<template>
  <v-container fluid id="tnc">
    <v-container>
      <v-card class="py-2 py-md-7 px-2 px-md-6">
        <v-row justify="center" align="center">
          <v-col cols="12" sm="12" md="6">
            <h1 class="text-center increasedFontSize ml-md-9 ml-sm-0">Terms and Conditions</h1>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-img
              height="225"
              contain
              src="@/assets/misc/undraw_Terms_re_6ak4.svg"
            ></v-img>
          </v-col>
        </v-row>
        <v-row justify="center" align="center" class="mt-4">
          <v-col cols="12" sm="12" md="9">
            <p>
              These terms and conditions outline the rules and regulations for
              the use of PlusMinus's Website, located at
              <a href="https://plusminusedu.com">plusminusedu.com</a>.
            </p>

            <p class="mt-4">
              By accessing this website we assume you accept these terms and
              conditions. Do not continue to use Plusminusedu if you do not
              agree to take all of the terms and conditions stated on this page.
            </p>

            <p class="mt-4">
              The following terminology applies to these Terms and Conditions,
              Privacy Statement and Disclaimer Notice and all Agreements:
              "Client", "You" and "Your" refers to you, the person log on this
              website and compliant to the Company’s terms and conditions. "The
              Company", "Ourselves", "We", "Our" and "Us", refers to our
              Company. "Party", "Parties", or "Us", refers to both the Client
              and ourselves. All terms refer to the offer, acceptance and
              consideration of payment necessary to undertake the process of our
              assistance to the Client in the most appropriate manner for the
              express purpose of meeting the Client’s needs in respect of
              provision of the Company’s stated services, in accordance with and
              subject to, prevailing law of Netherlands. Any use of the above
              terminology or other words in the singular, plural, capitalization
              and/or he/she or they, are taken as interchangeable and therefore
              as referring to same.
            </p>

            <!-- Cookies -->
            <p class="title mt-6">Cookies</p>

            <p class="mt-4">
              We employ the use of cookies. By accessing Plusminusedu, you
              agreed to use cookies in agreement with the PlusMinus's Privacy
              Policy.
            </p>

            <p class="mt-4">
              Most interactive websites use cookies to let us retrieve the
              user’s details for each visit. Cookies are used by our website to
              enable the functionality of certain areas to make it easier for
              people visiting our website. Some of our affiliate/advertising
              partners may also use cookies.
            </p>

            <!-- License -->
            <p class="title mt-6">License</p>

            <p class="mt-4">
              Unless otherwise stated, PlusMinus and/or its licensors own the
              intellectual property rights for all material on Plusminusedu. All
              intellectual property rights are reserved. You may access this
              from Plusminusedu for your own personal use subjected to
              restrictions set in these terms and conditions.
            </p>

            <p class="mt-4">
              You must not:
              <ul class="mt-2">
                <li><p>Republish material from Plusminusedu</p></li>
                <li><p>Sell, rent or sub-license material from Plusminusedu</p></li>
                <li><p>Reproduce, duplicate or copy material from Plusminusedu</p></li>
                <li><p>Redistribute content from Plusminusedu</p></li>
              </ul>
            </p>

            <p class="mt-4">
              This Agreement shall begin on the date hereof. Our Terms and Conditions were created with the help of the Terms And Conditions Generator.
            </p>
            
            <p class="mt-4">
              Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. PlusMinus does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of PlusMinus,its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions. To the extent permitted by applicable laws, PlusMinus shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.
            </p>

            <p class="mt-4">
              PlusMinus reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive or causes breach of these Terms and Conditions.
            </p>

            <p class="mt-4">
              You warrant and represent that:
              <ul class="mt-2">
                <li><p>You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;</p></li>
                <li><p>The Comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party;</p></li>
                <li><p>The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is an invasion of privacy</p></li>
                <li><p>The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.</p></li>
              </ul>
            </p>
            
            <p class="mt-4">
              You hereby grant PlusMinus a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats or media.
            </p>

            <!-- Hyperlinking to our Content -->
            <p class="title mt-6">Hyperlinking to our Content</p>

            <p class="mt-4">
              The following organizations may link to our Website without prior written approval:
              <ul class="mt-2">
                <li><p>Government agencies;</p></li>
                <li><p>Search engines;</p></li>
                <li><p>News organizations;</p></li>
                <li><p>Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and</p></li>
                <li><p>System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.</p></li>
              </ul>
            </p>

            <p class="mt-4">
              These organizations may link to our home page, to publications or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party’s site.
            </p>

            <p class="mt-4">
              We may consider and approve other link requests from the following types of organizations:
              <ul class="mt-2">
                <li><p>commonly-known consumer and/or business information sources;</p></li>
                <li><p>dot.com community sites;</p></li>
                <li><p>online directory distributors;</p></li>
                <li><p>internet portals;</p></li>
                <li><p>accounting, law and consulting firms; and</p></li>
                <li><p>educational institutions and trade associations.</p></li>
              </ul>
            </p>

            <p class="mt-4">
              We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of PlusMinus; and (d) the link is in the context of general resource information.
            </p>

            <p class="mt-4">
              These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party’s site.
            </p>

            <p class="mt-4">If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to PlusMinus. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.</p>

            <p class="mt-4">
             Approved organizations may hyperlink to our Website as follows:
              <ul class="mt-2">
                <li><p>By use of our corporate name; or</p></li>
                <li><p>By use of the uniform resource locator being linked to; or</p></li>
                <li><p>By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party’s site.</p></li>
              </ul>
            </p>

            <p class="mt-4">
              No use of PlusMinus's logo or other artwork will be allowed for linking absent a trademark license agreement.
            </p>

            <!-- iFrames -->
            <p class="title mt-6">iFrames</p>

            <p class="mt-4">Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.</p>

            <!-- Content Liability -->
            <p class="title mt-6">Content Liability</p>

            <p class="mt-4">
              We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.
            </p>

            <!-- Your Privacy -->
            <p class="title mt-6">Privacy Policy</p>

            <p class="mt-4">Please read our <router-link to="/privacy-policy">Privacy Policy</router-link></p>

            <!-- Reservation of Rights -->
            <p class="title mt-6">Reservation of Rights</p>

            <p class="mt-4">
              We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amen these terms and conditions and it’s linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.
            </p>

            <!-- Removal of links from our website -->
            <p class="title mt-6">Removal of links from our website</p>

            <p class="mt-4">
              If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.
            </p>

            <p class="mt-4">
              We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.
            </p>

            <!-- Disclaimer -->
            <p class="title mt-6">Disclaimer</p>

            <p class="mt-4">
             To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will:
              <ul class="mt-2">
                <li><p>limit or exclude our or your liability for death or personal injury;</p></li>
                <li><p>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</p></li>
                <li><p>limit any of our or your liabilities in any way that is not permitted under applicable law; or</p></li>
                <li><p>exclude any of our or your liabilities that may not be excluded under applicable law.</p></li>
              </ul>
            </p>

            <p class="mt-4">
              The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.
            </p>

            <p class="mt-4">
              As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.
            </p>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "TnC",
  metaInfo: {
    title: "Terms and Conditions",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "PlusMinus aka plusminusedu.com Terms and Conditions, privacy policy, refund policy and guidelines.",
      },
      {
        vmid: "keywords",
        name: "keywords",
        content: "terms, conditions, privacy, guidelines, tnc, policy, refund",
      },
      {
        vmid: "robots",
        name: "robots",
        content: "index, follow",
      },
    ],
  },
};
</script>

<style>
.increasedFontSize {
  font-size: 3.98rem !important;
}
</style>